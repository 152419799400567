import { default as aboutrJ3iymNuehMeta } from "/home/runner/work/pool-craft/pool-craft/pages/about.vue?macro=true";
import { default as contactL5hyTcFfIXMeta } from "/home/runner/work/pool-craft/pool-craft/pages/contact.vue?macro=true";
import { default as galleryxidQULK47dMeta } from "/home/runner/work/pool-craft/pool-craft/pages/gallery.vue?macro=true";
import { default as indexGl1DMurNW1Meta } from "/home/runner/work/pool-craft/pool-craft/pages/index.vue?macro=true";
import { default as our_45servicesAJhEZ21MajMeta } from "/home/runner/work/pool-craft/pool-craft/pages/our-services.vue?macro=true";
import { default as our_45workr2OZ6ptXweMeta } from "/home/runner/work/pool-craft/pool-craft/pages/our-work.vue?macro=true";
export default [
  {
    name: aboutrJ3iymNuehMeta?.name ?? "about",
    path: aboutrJ3iymNuehMeta?.path ?? "/about",
    meta: aboutrJ3iymNuehMeta || {},
    alias: aboutrJ3iymNuehMeta?.alias || [],
    redirect: aboutrJ3iymNuehMeta?.redirect,
    component: () => import("/home/runner/work/pool-craft/pool-craft/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactL5hyTcFfIXMeta?.name ?? "contact",
    path: contactL5hyTcFfIXMeta?.path ?? "/contact",
    meta: contactL5hyTcFfIXMeta || {},
    alias: contactL5hyTcFfIXMeta?.alias || [],
    redirect: contactL5hyTcFfIXMeta?.redirect,
    component: () => import("/home/runner/work/pool-craft/pool-craft/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: galleryxidQULK47dMeta?.name ?? "gallery",
    path: galleryxidQULK47dMeta?.path ?? "/gallery",
    meta: galleryxidQULK47dMeta || {},
    alias: galleryxidQULK47dMeta?.alias || [],
    redirect: galleryxidQULK47dMeta?.redirect,
    component: () => import("/home/runner/work/pool-craft/pool-craft/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: indexGl1DMurNW1Meta?.name ?? "index",
    path: indexGl1DMurNW1Meta?.path ?? "/",
    meta: indexGl1DMurNW1Meta || {},
    alias: indexGl1DMurNW1Meta?.alias || [],
    redirect: indexGl1DMurNW1Meta?.redirect,
    component: () => import("/home/runner/work/pool-craft/pool-craft/pages/index.vue").then(m => m.default || m)
  },
  {
    name: our_45servicesAJhEZ21MajMeta?.name ?? "our-services",
    path: our_45servicesAJhEZ21MajMeta?.path ?? "/our-services",
    meta: our_45servicesAJhEZ21MajMeta || {},
    alias: our_45servicesAJhEZ21MajMeta?.alias || [],
    redirect: our_45servicesAJhEZ21MajMeta?.redirect,
    component: () => import("/home/runner/work/pool-craft/pool-craft/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: our_45workr2OZ6ptXweMeta?.name ?? "our-work",
    path: our_45workr2OZ6ptXweMeta?.path ?? "/our-work",
    meta: our_45workr2OZ6ptXweMeta || {},
    alias: our_45workr2OZ6ptXweMeta?.alias || [],
    redirect: our_45workr2OZ6ptXweMeta?.redirect,
    component: () => import("/home/runner/work/pool-craft/pool-craft/pages/our-work.vue").then(m => m.default || m)
  }
]