import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig()

    const firebaseConfig = {
        apiKey: config.public.FB_API_KEY,
        projectId: config.public.FB_PROJECT_ID,
        appId: config.public.FB_APP_ID,
    };

    const app = initializeApp(firebaseConfig)

    const firestore = getFirestore(app)
    const functions = getFunctions(app)

    if (config.public.ENV !== 'production') {
        connectFirestoreEmulator(firestore, 'localhost', 8080)
        connectFunctionsEmulator(functions, 'localhost', 5001)
    }

    nuxtApp.vueApp.provide('firestore', firestore)
    nuxtApp.provide('firestore', firestore)

    nuxtApp.vueApp.provide('functions', functions)
    nuxtApp.provide('functions', functions)
})