<template>
  <div id="app">
    <loading 
      v-model:active="isLoading"
      :can-cancel="false" 
      :is-full-page="true"
      color="#1057da"
      :opacity=1
      :width=100
      :height=100>
      <template #after>
        <img src="https://imagedelivery.net/qnTDfaSAYDET9GI1h1bgDw/pool-craft/loading_logo.png/public" alt="Loading site" />
      </template>
    </loading>
    <template v-if="!isLoading">
      <Navigation></Navigation>
      <NuxtPage page-key="static" />
      <Footer />
    </template>
  </div>
</template>

<script setup>
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css';

let isLoading = ref(true)

function loadImage(url) {
  return new Promise(function(resolve, reject) {
    var img = new Image();
    img.onload = function() {
      resolve(url);
    }
    img.onerror = function() {
      reject(url);
    }
    img.src = url;
  });
}

onMounted(() => {  
  isLoading.value = false;
  // Promise.allSettled([
  //   loadImage('https://imagedelivery.net/qnTDfaSAYDET9GI1h1bgDw/pool-craft/homeslide.jpg/public'),
  //   loadImage('https://imagedelivery.net/qnTDfaSAYDET9GI1h1bgDw/pool-craft/about_new.jpg/public'),
  //   loadImage('https://imagedelivery.net/qnTDfaSAYDET9GI1h1bgDw/pool-craft/contact.jpg/public'),
  // ]).then(() => {
  //   console.log('All main images loaded.');
  // }).catch(() => {
  //   console.error('Error processing images');
  // }).finally(() => {
  //     isLoading.value = false;
  // });
});
</script>
