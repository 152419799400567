import revive_payload_client_4sVQNw7RlN from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/pool-craft/pool-craft/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/pool-craft/pool-craft/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directus_BNSudx5Icl from "/home/runner/work/pool-craft/pool-craft/plugins/directus.js";
import firebase_client_nS52P0yitD from "/home/runner/work/pool-craft/pool-craft/plugins/firebase.client.js";
import fontawesome_klhsrycjcK from "/home/runner/work/pool-craft/pool-craft/plugins/fontawesome.js";
import hotjar_HHyDP6XDX8 from "/home/runner/work/pool-craft/pool-craft/plugins/hotjar.js";
import sentry_client_shVUlIjFLk from "/home/runner/work/pool-craft/pool-craft/plugins/sentry.client.ts";
import vue_lazy_myVq5pH2r9 from "/home/runner/work/pool-craft/pool-craft/plugins/vue-lazy.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  directus_BNSudx5Icl,
  firebase_client_nS52P0yitD,
  fontawesome_klhsrycjcK,
  hotjar_HHyDP6XDX8,
  sentry_client_shVUlIjFLk,
  vue_lazy_myVq5pH2r9
]