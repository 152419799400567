<template>
    <footer>
        <div class="container" style="display: none;">
            <div class="content">
                <div class="item">
                    <h2>Contact</h2>
                    <h3>Steve Donaldson</h3>
                    <div class='icon-left'>
                        <div class='icon'>
                            <font-awesome-icon icon="phone" />
                        </div>
                        <div class="content">
                            <a href="tel:0210749532">0210749532</a>
                        </div>
                        <div class='icon'>
                            <font-awesome-icon icon="envelope" />
                        </div>
                        <div class="content">
                            <a href="mailto:info@poolcraft.co.nz">info@poolcraft.co.nz</a>
                        </div>
                    </div>

                    <h3>Bianca Donaldson</h3>
                    <div class='icon-left'>
                        <div class='icon'>
                            <font-awesome-icon icon="phone" />
                        </div>
                        <div class="content">
                            <a href="tel:02102707749">02102707749</a>
                        </div>
                        <div class='icon'>
                            <font-awesome-icon icon="envelope" />
                        </div>
                        <div class="content">
                            <a href="mailto:info@poolcraft.co.nz">info@poolcraft.co.nz</a>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2>Areas We Service</h2>
                    <ul>
                        <li>North Shore</li>
                        <li>Central Auckland</li>
                        <li>West Auckland</li>
                        <li>South Auckland</li>
                        <li>East Auckland</li>
                        <li>Greater Auckland region</li>
                    </ul>
                </div>

                <div class="item">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><nuxt-link to="/">Home</nuxt-link></li>
                        <li><nuxt-link to="/about">About</nuxt-link></li>
                        <li><nuxt-link to="/contact">Contact</nuxt-link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container copy-right">
            <div class="content">
                <p>© 2024 Pool Craft LTD. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
</template>