import Hotjar from '@hotjar/browser';

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig()
    const siteId = 4946048;
    const hotjarVersion = 6;

    if (config.public.ENV !== 'production')  {
        return;
    }

    Hotjar.init(siteId, hotjarVersion);

    // Initializing with `debug` option:
    Hotjar.init(siteId, hotjarVersion, {
        debug: config.public.ENV !== 'production'
    });
});